import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ApiService from "../../services/ApiService";
import { handleErrors, handleLoginErrorNavigation, showNotification } from "../../services/HelperMethods";
import AppLoading from '../AppLoading/AppLoading';
import { Drawer } from 'antd';
import AppButton from '../AppButton/AppButton';
import { UserDetailsContext } from '../../store/Store';
interface Apartment {
  id: string;
  name: string;
  location: string;
  floor: number;
  doorNumber: number;
  isOwnerAvailable: boolean;
  isTenantAvailable: boolean;
}

interface Building {
  id: string;
  name: string;
  city: string;
}

const SelectApartmentScreen = () => {
  const history = useHistory();
  const apiService = new ApiService();
  let { id } = useParams() as any;
  console.log("Id:", id);
  
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
    history.push('/users');
  };
  const [selectedApartment, setSelectedApartment] = useState({} as any);
  const [apartments, setApartments] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [buildings, setBuildings] = useState([] as any[]);
  const [accountType, setAccountType] = useState() as any;
  const [canSubmit, setCanSubmit] = useState(false);
  const [isOwnerAvailable, setIsOwnerAvailable] = useState(false);
  const [isTenantAvailable, setIsTenantAvailable] = useState(false);
  const [user, set] = useContext(UserDetailsContext);
  
  const navigateAfterScreen = history.location.state?.navigateAfter;

  useEffect(() => {
    getBuildings();
  }, []);

  useEffect(() => {
    if (user?.buildingId) {
      getApartments(user?.buildingId);
    }
  }, [user]);

  useEffect(() => {
    if (selectedApartment?.id) {
      getOwner();
    }
  }, [selectedApartment]);

  const getOwner = async () => {
    try {
      let  data = { apartmentId: selectedApartment?.id, buildingId: user?.buildingId };
      let  result = await apiService.checkApartmentOwner(data);
      setIsOwnerAvailable(result.data.isOwnerAvailable);
      setIsTenantAvailable(result.data.isTenantAvailable);
    } catch (error) {
      setIsOwnerAvailable(true);
      setIsTenantAvailable(true);
      handleErrors(error);
    }
  };

  const getBuildings = async () => {
    setIsLoading(true);
    try {
      let result = await apiService.getPublicBuildingList() as any;
      setBuildings(result.data.buildings);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const getApartments = async (buildingId) => {
    setIsLoading(true);
    setCanSubmit(false);
    try {
      let result =  (await apiService.getApartmentss({ buildingId: buildingId, })) as any
      result = result.data;
      result?.apartments.sort(function (a, b) {
        if (a.location < b.location) { return -1; }
        if (a.location > b.location) { return 1; }
        return 0;
      })  

      result?.apartments.sort(function (a, b) {
        if (a.doorNumber < b.doorNumber) { return -1; }
        if (a.doorNumber > b.doorNumber) { return 1; }
        return 0;
      })

      result?.apartments.sort(function (a, b) {
        return a.floor - b.floor;
      })
      let apartments = result?.apartments.map((ap) => {
        return { ...ap, name: `${ap.location} - ${ap.floor} - ${ap.doorNumber}` };
      });

      // debugger;

      // if (accountType === 1) { // If "I'm the Owner" is selected
      //   apartments = result?.apartments.map((ap) => {
      //     return { ...ap, name: `${ap.location} - ${ap.floor} - ${ap.doorNumber}` };
      //   });
      // } else { // If "I'm the Tenant" is selected or no account type is selected
      //   apartments = result?.apartments
      //     .filter((ap) => !ap.isTenantAvailable) // Only include apartments where a tenant is available
      //     .map((ap) => {
      //       return { ...ap, name: `${ap.location} - ${ap.floor} - ${ap.doorNumber}` };
      //     });
      // }  

      setApartments(apartments);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const handleApartmentSelect = async () => {
    setIsLoading(true);
    try {
      await apiService.selectApartment({
        apartmentId: apartments.find((apartment) => { return apartment.name == selectedApartment.name; }).id,
        buildingId: user?.buildingId,
        accountType: accountType,
        userId: id,
      });
  
      if (navigateAfterScreen) {
        history.push(navigateAfterScreen);
      } else {
        history.push("/users");
        showNotification("Added New User", "Added New User", "success");
      }
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };


  // const setUserAccountType = (type) => {
  //   if (type == 1 && !isOwnerAvailable) {
  //     setAccountType(1);
  //   } else if (type == 1 && isOwnerAvailable) {
  //     showNotification('Error', 'Apartment Owner Already Available', 'error');
  //   }
  //   if (type == 2 && !isTenantAvailable) {
  //     setAccountType(2);
  //   } else if (type == 2 && isTenantAvailable) {
  //     showNotification('Error', 'Tenant Already Available', 'error');
  //   }
  // };

  useEffect(() => {
    if (selectedApartment && accountType) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [selectedApartment, accountType]);

  return (
    <Drawer
      title="Select Apartment"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      key="right"
    >
          <Container className="my-5">
      {isLoading ? (
        <AppLoading isLoading={isLoading} />
      ) : (
        <>
          <h1 className="mb-4">Select Your Apartment</h1>

          {/* <Form.Group className="mb-3">
            <Form.Label>Apartment Complex</Form.Label>
            <Form.Control
              as="select"
              value={selectedBuildingId}
              onChange={(e) => {
                setSelectedBuildingId(e.target.value);
                getApartments(e.target.value);
              }}
            >
              <option value="">Select Your Apartment Complex</option>
              {buildings.map((building) => (
                <option key={building.id} value={building.id}>
                  {building.name} - {building.city}
                </option>
              ))}
            </Form.Control>
          </Form.Group> */}
          <Form.Group className="mb-3">
            <Form.Label>Apartment Number</Form.Label>
            <Dropdown>
                <Dropdown.Toggle
                  variant={accountType === 1 ||2  ? 'primary' : 'outline-primary'}
                  id="dropdown-basic"
                  className="w-100"
                >
                  {selectedApartment?.name || 'Select Your Apartment'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {apartments.map((apartment) => (
                    <Dropdown.Item
                      key={apartment.id}
                      onClick={() => setSelectedApartment(apartment)}
                    >
                      {apartment.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
          </Form.Group>
          
          <div className="mt-3 text-center">
            {/* <PrivacyText /> */}          
            <Row className="mb-4 justify-content-center">
            <Col md={6} className="d-flex justify-content-around">
              <Button
                className={`w-100 me-2`}
                onClick={() => setAccountType(1)}
                variant={accountType == 1 ? 'success' : 'warning'}
              >
                I'm the Owner
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className={`w-100 ms-2`}
                onClick={() => setAccountType(2)}
                variant={accountType == 2 ? 'success' : 'warning'}
              >
                I'm the Tenant
              </Button>
            </Col>
          </Row>
          </div>
          <Row className="justify-content-end">
            <Col md={12}>
              <AppButton
              style={{
                background:"primary",
                width: '100%'
              }}
              
                onClick={handleApartmentSelect}
              >
                Next
              </AppButton>
            </Col>
          </Row>
        </>
      )}
    </Container>
    </Drawer>

  );
};

export default SelectApartmentScreen;