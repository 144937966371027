import { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { handleErrors, showNotification } from "../../services/HelperMethods";
import { Table } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import AppButton from "../../components/AppButton/AppButton";
import TickCircle from "../../components/TickCircle/TickCircle";
import AppLoading from "../../components/AppLoading/AppLoading";

interface Building {
  id: number;
  address: string;
  buildingId: number;
  buildingName: string;
  contactNumber: string;
  email: string;
  planNumber: string;
  requestUserName: string;
}

function RequestBuilding() {
  const apiService = new ApiService();

  const [reqBuilding, setReqBuilding] = useState<Building[]>([]);
  const [building, setBuilding] = useState<Building>();
  const [open, setOpen] = useState<boolean>(false);

  const [updateName, setUpdateName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllRequestBuildings();
  }, []);

  async function getAllRequestBuildings() {
    setIsLoading(true);
    await apiService
      .SelfSignupGetAllRequestBuilding()
      .then((response) => {
        if (response.data?.isSuccess) {
          setReqBuilding(response.data?.buildings);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrors(error);
      });
  }

  function handleClickReqBuilding(building: Building) {
    setOpen(true);
    setBuilding(building);
    setUpdateName(building?.buildingName);
  }

  function handleClose() {
    setOpen(false);
    clearFields();
  }

  function clearFields() {
    setCity("");
    setAccountNumber("");
    setBankName("");
  }

  function handleBuildingNameText(event) {
    setUpdateName(event.target.value);
  }

  function handleCityNameText(event) {
    setCity(event.target.value);
  }

  function handleBankeNameText(event) {
    setBankName(event.target.value);
  }

  function handleAccountNumberText(event) {
    setAccountNumber(event.target.value);
  }

  async function handleRegister() {
    let data = {
      buildingId: building?.buildingId,
      buildingName: updateName,
      city: city,
      bankName: bankName,
      accountNumber: accountNumber,
    };

    await apiService
      .SelfSignupChangeBuildingStatus(data)
      .then((reponse) => {
        if (reponse.data?.result?.isSuccess) {
          setReqBuilding(reqBuilding?.filter((item) => item !== building));
          showNotification("Success", "Register Successfully", "success");
        }
      })
      .catch((error) => {
        handleErrors(error);
      });

    handleClose();
  }

  return (
    <div>
      {isLoading ? (
        <AppLoading isLoading={isLoading} />
      ) : (
        <div>
          <h1>Request Buildings</h1>
          {reqBuilding?.length ? (
            <div>
              <Table responsive="lg" borderless={true}>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Building Name</th>
                    <th>Plan Number</th>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {reqBuilding.map((building: any, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => handleClickReqBuilding(building)}
                      >
                        <td>{index + 1}</td>
                        <td>{building?.buildingName}</td>
                        <td>{building?.planNumber}</td>
                        <td>{building?.requestUserName}</td>
                        <td>{building?.email}</td>
                        <td>{building?.contactNumber}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{building?.buildingName}</DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  style={{ position: "absolute", right: 8, top: 8 }}
                >
                  <TickCircle icon="close" large />
                </IconButton>
                <DialogContent>
                  <DialogContentText>
                    Apartment Name :{" "}
                    <input
                      type="text"
                      value={updateName}
                      onChange={handleBuildingNameText}
                    />
                  </DialogContentText>
                  <DialogContentText>
                    Address: {building?.address}
                  </DialogContentText>
                  <DialogContentText>
                    City :{" "}
                    <input
                      type="text"
                      value={city}
                      onChange={handleCityNameText}
                    />
                  </DialogContentText>
                  <DialogContentText>
                    Bank Name :{" "}
                    <input
                      type="text"
                      value={bankName}
                      onChange={handleBankeNameText}
                    />
                  </DialogContentText>
                  <DialogContentText>
                    Account Number :{" "}
                    <input
                      type="text"
                      value={accountNumber}
                      onChange={handleAccountNumberText}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <AppButton
                    style={{
                      background: "primary",
                      width: "35%",
                    }}
                    onClick={handleRegister}
                  >
                    Register
                  </AppButton>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Not found any request
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RequestBuilding;
