import React, { Fragment, useContext, useEffect, useState } from 'react'
import TickCircle from '../../components/TickCircle/TickCircle'
import ApiService from '../../services/ApiService'
import {
  formatMoney,
  handleErrors,
  splitWord,
  showNotification
} from '../../services/HelperMethods'
import DashboardFilter from '../../components/DashboardFilter/DashboardFilter'
import Chart from 'chart.js'
import AppLoading from '../../components/AppLoading/AppLoading'
import moment from 'moment'
import defaultImg from '../../assets/images/default.jpg'
import { Link } from 'react-router-dom'

import './Dashboard.scss'
import AccountingMain from '../Accounting/AccountingMain'
import { OpenAccountingPageContext } from '../../store/Store'

function Dashboard ({ history }) {
  const apiService = new ApiService()

  const [paymentStatus, setPaymentStatus] = useState(null as any)
  const [userApartmentStatus, setUserApartmentStatus] = useState(null as any)
  const [amenityStatus, setAmenityStatus] = useState([] as any[])
  const [isLoading, setIsLoading] = useState(false)
  const [graphData, setGraphData] = useState([] as any[])
  const [paymentChart, setPaymentChart] = useState(null as any)
  const [pendingPayments, setPendingPayments] = useState([])
  const [incomeReportFilter, setIncomeReportFilter] = useState({
    fromDate: moment().startOf('week'),
    toDate: moment().endOf('week'),
    i: 0
  })
  const [facilityBookingFilter, setFacilityBookingFilter] = useState({
    fromDate: moment().startOf('day'),
    toDate: moment().endOf('day'),
    i: 0
  })
const [openAccountingPage, setOpenAccountingPage] = useContext(OpenAccountingPageContext);
  const chartRef = React.createRef() as any

  useEffect(() => {
    getReports(chartRef?.current?.getContext('2d'))
  }, [incomeReportFilter, facilityBookingFilter])

  async function paymentReminder (invoiceId) {
    setIsLoading(true)

    let data = { invoiceId: invoiceId }

    await apiService
      .paymentReminder(data)
      .then(result => {
        let data = result.data?.isSuccessful

        if (data) {
          getReports(chartRef?.current?.getContext('2d'))
        } else {
          showNotification(
            'Error Sending Reminder',
            'Error Sending Reminder',
            'error'
          )
        }
      })
      .catch(() => {
        showNotification(
          'Error Sending Reminder',
          'Error Sending Reminder',
          'error'
        )
      })

    setIsLoading(false)
  }

  function renderChart (chartRef, months, monthIncome, monthExpenses) {
    if (!chartRef) {
      return
    }

    paymentChart?.destroy()

    let x = new Chart(chartRef, {
      type: 'bar',
      data: {
        labels: months,
        datasets: [
          {
            label: 'Income',
            data: monthIncome,
            backgroundColor: '#8BCB89',
            borderWidth: 0
          },
          {
            label: 'Expenses',
            data: monthExpenses,
            backgroundColor: '#FEBE4D',
            borderWidth: 0
          }
        ]
      },
      options: {
        backgroundColor: 'red'
      }
    })

    setPaymentChart(x)
  }

  function formatChartData (chartData) {
    let temp = [] as any[]
    let formattedChartData = [] as any[]

    chartData?.forEach(singleObj => {
      const date = singleObj.date.split('T')[0]

      if (temp[date]) {
        temp[date].push(singleObj)
      } else {
        temp[date] = [singleObj]
      }
    })

    Object.values(temp).forEach(element => {
      var date = element[0].date
      var invoiceValue = 0
      var expenseValue = 0

      element.forEach(x => {
        invoiceValue = invoiceValue + x.totalIncomeAmount
        expenseValue = expenseValue + x.totalExpenseAmount
      })

      var data = {
        date: date,
        totalIncomeAmount: invoiceValue,
        totalExpenseAmount: expenseValue
      }

      formattedChartData.push(data)
    })

    return formattedChartData
  }

  async function getReports (chartRef) {
    setIsLoading(true)

    try {
      await apiService.getDashboardPayment().then(result => {
        var data = result.data
        setPaymentStatus(data)
      })

      await apiService.usersApartmentStatus().then(result => {
        var data = result.data
        setUserApartmentStatus(data)
      })

      await apiService
        .amenityBookingStatus({
          toDate: facilityBookingFilter.toDate,
          fromDate: facilityBookingFilter.fromDate
        })
        .then(result => {
          var data = result.data.amenities
          setAmenityStatus(data)
        })

      await apiService
        .getPaymentGraph({
          toDate: incomeReportFilter.toDate,
          fromDate: incomeReportFilter.fromDate
        })
        .then(result => {
          let data = result.data

          setGraphData(data?.list)

          var formattedData = formatChartData(data?.list)

          const sortedArray = formattedData.sort((a, b) => {
            return moment(a?.date).diff(b?.date)
          })

          let dates = sortedArray?.map(val => {
            return moment(val?.date).format('LL')
          })

          let incomeList = sortedArray?.map(val => {
            return val.totalIncomeAmount
          })

          let expensesList = sortedArray?.map(val => {
            return val.totalExpenseAmount
          })

          renderChart(chartRef, dates, incomeList, expensesList)
        })

      await apiService.getPendingPayments().then(result => {
        let data = result.data?.pendingPayments
        setPendingPayments(data)
      })
    } catch (error) {
      handleErrors(error)
    }
    setIsLoading(false)
  }

  return (
    <div className='body dashboard-screen'>
      {/* trick to make accounting UI loads faster, fix later */}
      {/* <div className='hidden'>
        <AccountingMain />
      </div> */}
      {/* <AppLoading isLoading={isLoading} /> */}
      <h2 color='cst-btn-background'>Dashboard</h2>
      <div className='dashboard-grid'>
        <div className='payment-status-wrap'>
          <div className='green-bg value-card'>
            <div className='d-flex flex-column'>
              <span className='value-card-heading'>Total Billed</span>
              <span className='value-card-amount'>
                LKR {formatMoney(paymentStatus?.totalBilled)}
              </span>
            </div>
          </div>

          <div className='yellow-bg value-card'>
            <div className='d-flex flex-column'>
              <span className='value-card-heading'>Total Collected</span>
              <span className='value-card-amount'>
                LKR {formatMoney(paymentStatus?.totalCollected)}
              </span>
            </div>
          </div>

          <div className='red-bg value-card'>
            <div className='d-flex flex-column'>
              <span className='value-card-heading'>Total Pending</span>
              <span className='value-card-amount'>
                LKR {formatMoney(paymentStatus?.totalPending)}
              </span>
            </div>
          </div>
        </div>

        <div className='statistics-wrap'>
          <div className='title-wrap'>
            <h4>Condo Statistics</h4>
          </div>

          <div className='d-flex flex-row justify-content-between condo-section'>
            <div className='d-flex flex-row justify-content-center align-items-center'>
              <div className='condo-icons occupied d-flex justify-content-center align-items-center'>
                <TickCircle large />
              </div>

              <div className='d-flex flex-column mr-2'>
                <span className='font-dark-grey condo-stats-heading'>
                  Occupied
                </span>
                <span className='font-dark-grey condo-stats-amount'>
                  {userApartmentStatus?.totalOccupied}
                </span>
              </div>
            </div>

            <div className='d-flex flex-row justify-content-center align-items-center'>
              <div className='condo-icons vacant d-flex justify-content-center align-items-center'>
                <TickCircle icon='minus' large />
              </div>

              <div className='d-flex flex-column mr-2'>
                <span className='font-dark-grey condo-stats-heading'>
                  Vacant
                </span>
                <span className='font-dark-grey condo-stats-amount'>
                  {userApartmentStatus?.totalVacant}
                </span>
              </div>
            </div>

            <div className='d-flex flex-row justify-content-center align-items-center'>
              <div className='condo-icons users d-flex justify-content-center align-items-center'>
                <TickCircle icon='user' large />
              </div>

              <div className='d-flex flex-column mr-2'>
                <span className='font-dark-grey condo-stats-heading'>
                  Users
                </span>
                <span className='font-dark-grey condo-stats-amount'>
                  {userApartmentStatus?.totalUsers}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='d-flex flex-row justify-content-between mb-3'>
            <h4>Income and Expense Report</h4>
            <DashboardFilter
              labels={['This Week', 'This Month', 'This Year']}
              showCustom={false}
              selectedIndex={incomeReportFilter?.i}
              onClick={(i, startDate, endDate) => {
                switch (i) {
                  case 0:
                    setIncomeReportFilter({
                      i: i,
                      fromDate: moment().utc().startOf('week'),
                      toDate: moment().utc().endOf('week')
                    })
                    break
                  case 1:
                    setIncomeReportFilter({
                      i: i,
                      fromDate: moment().utc().startOf('month'),
                      toDate: moment().utc().endOf('month')
                    })
                    break
                  case 2:
                    setIncomeReportFilter({
                      i: i,
                      fromDate: moment().utc().startOf('year'),
                      toDate: moment().utc().endOf('year')
                    })
                    break
                  default:
                    setIncomeReportFilter({
                      i: i,
                      fromDate: startDate,
                      toDate: endDate
                    })
                    break
                }
              }}
            />
          </div>
          <canvas ref={chartRef} />
        </div>

        <div className='stats-wrapper'>
          <div>
            <div className='d-flex flex-row justify-content-between'>
              <h4 className='aging-style'>
                Pending Collection Summary{' '}
                {/* <span className="count">{" "}<span style={{ marginBottom: "0.1rem" }}>10</span></span> */}
              </h4>
              <Link 
                to="/accounting/" 
                className={'view' + (openAccountingPage == "invoices" ? " submenu-active" : "")} 
                onClick={() => setOpenAccountingPage("invoices")}
                >
              View All
            </Link>
            </div>

            <div className='d-flex flex-row box-section'>
              <div className='timeline'>
                {pendingPayments.length > 0 ? (
                  pendingPayments.map((pending: any, i) => {
                    return (
                      <div key={i} className='msg'>
                        <div className='timeline-single'>
                          <div className='user'>
                            <img
                              className='user_avatar'
                              src={
                                pending?.profileImageURL
                                  ? pending?.profileImageURL
                                  : defaultImg
                              }
                            />
                            <div className='user_details'>
                              <div className='user_name'>
                                {pending?.name}{' '}
                                <div className='user_role'>
                                  {splitWord(pending?.userType)}
                                  {' - '}
                                  {pending?.apartmentDoorNumber}
                                </div>
                              </div>

                              <div className='user_payment_name'>
                                Issued{' '}
                                <Link
                                  to={'/accounting/'}
                                  className={'user_payment_time'+ (openAccountingPage == "invoices" ? " submenu-active" : "")}
                                  onClick={() => setOpenAccountingPage("invoices")}
                                >
                                  {moment
                                    .utc(pending?.invoiceIssuedDate)
                                    .fromNow()}
                                </Link>
                              </div>

                              <div className='user_reminder_name'>
                                Last Reminder{' '}
                                <span className='user_reminder_time'>
                                  {pending?.lastReminderDate
                                    ? moment
                                        .utc(pending?.lastReminderDate)
                                        .fromNow()
                                    : ' - '}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className='date'>
                            <div className='date_content'>
                              <div className='amount'>
                                {'LKR '}
                                {formatMoney(pending?.amount)}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  marginTop: '0.6rem'
                                }}
                              >
                                {/* <i style={{ color: "#4bbb46", margin: "0rem 1rem" }}
                                  onClick={() => { }} className="fas fa-check" /> */}
                                <i
                                  className='fas fa-bell'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    paymentReminder(pending?.invoiceId)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>

          <div>
            <div className='d-flex flex-row justify-content-between mb-3'>
              <h4>Facility Bookings</h4>
              <DashboardFilter
                labels={['Today', 'This Week', 'This Month']}
                showCustom={false}
                selectedIndex={facilityBookingFilter?.i}
                onClick={(i, startDate, endDate) => {
                  switch (i) {
                    case 0:
                      setFacilityBookingFilter({
                        i: i,
                        fromDate: moment().startOf('day'),
                        toDate: moment().endOf('day')
                      })
                      break
                    case 1:
                      setFacilityBookingFilter({
                        i: i,
                        fromDate: moment().startOf('week'),
                        toDate: moment().endOf('week')
                      })
                      break
                    case 2:
                      setFacilityBookingFilter({
                        i: i,
                        fromDate: moment().startOf('month'),
                        toDate: moment().endOf('month')
                      })
                      break
                    default:
                      setFacilityBookingFilter({
                        i: i,
                        fromDate: startDate,
                        toDate: endDate
                      })
                      break
                  }
                }}
              />
            </div>
            <div
              style={{ flexWrap: 'wrap' }}
              className='d-flex flex-row box-section'
            >
              {amenityStatus?.map((amenity, i) => {
                return (
                  <div
                    key={i}
                    style={{ backgroundImage: `url(${amenity?.imageURL})` }}
                    className='box'
                  >
                    <div className='content'>
                      <span>{amenity?.name}</span>
                      <Link
                        to={'/facility/bookings'}
                        className='booking-counter'
                        style={{ color: 'white' }}
                      >
                        {amenity?.totalBookings}
                      </Link>
                      <span>Bookings</span>
                    </div>
                    <div className='blur'></div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
